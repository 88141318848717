import React from "react";
import Typography from "@mui/material/Typography";
import TheBox from "../components/TheBox";

export default function IntroActivity1(props){
    return(
        <React.Fragment>
                <Typography variant="h6">Activity 1</Typography>
                <Typography>Discuss the following contents: </Typography>
                <TheBox />
                <iframe 
                    width={anchoSegunBreakpoint(props.size)} height={ anchoSegunBreakpoint(props.size) * 0.587} src="https://www.youtube.com/embed/GKSod0uprJo" 
                    title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; 
                    clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>

                <iframe 
                    width={anchoSegunBreakpoint(props.size)} height={ anchoSegunBreakpoint(props.size) * 0.587} src="https://www.youtube.com/embed/xcvv8KdQKuQ" 
                    title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; 
                    clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>
                <TheBox />
        </React.Fragment>
    )
}

function anchoSegunBreakpoint(size){
    let margin = 25

    if (size["bpoint"] === "xl" || size["bpoint"] === "lg") {
        return 900 - (margin * 2)
    }
    return size["width"] - (margin * 2)
}