import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ExpandMore from "@mui/icons-material/ExpandMore";

export default function IntroBox1(){
    const elevationBaja = 3
    const elevationAlta = 10
    const [elevationAcc, setElevationAcc] = React.useState(elevationBaja)

    function toggleElevation(expanded){
        console.log(expanded)
        if (expanded){
            setElevationAcc(elevationAlta)
        }
        if (expanded === false){
            setElevationAcc(elevationBaja)
        }
    }

    return(
        <React.Fragment>
            <Accordion elevation={elevationAcc} onChange={ (event, expanded) => { toggleElevation(expanded) }}>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography align="justify" fontWeight={600} >Box 1: Public health and the urban built environment evolved tightly connected when they were first conceived and developed. </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography align="justify">
                            We have built shelter, villages, towns and cities during thousands of years. 
                            Our understanding concerning ancient urban settlements comes mostly from archaeological and ethnographic research, 
                            which has shed some light on earlier water and basic sanitation systems, markets, roads and centers of political power. 
                            However, we may have a limited understanding of the ideas and principles of “health”, a social construct with different meanings over time.  
                            Our limitations often reflect the colonial interpretation of “global” issues, in general, and public health in large cities, in particular. 
                        </Typography>

                        <Typography align="justify">
                            Archaeological excavations have revealed different developments and examples of ancient and complex cities.  
                            Public health concepts and practices in those settlements may have followed different trajectories too. For instance:
                        </Typography>

                        <Typography align="justify" style={{margin: 10}}>
                            - The Maya megalopolis (Late Classic period,  years 650 to 800 CE). Urban settlements displayed a complex integrative infrastructure for 7-11 million inhabitants (Readings, below). 
                        </Typography>

                        <Typography align="justify" style={{margin: 10}}>
                            - New technology (PLI Pacunam Lidar Initiative) used in recent archaeological research uncovered a large city (e.g., Tikal), 
                            surrounded by deliberately modified agricultural terrain (e.g., terraces, irrigation cannals), roads and defense-fortification features. 
                        </Typography>

                        <Typography align="justify" style={{margin: 10}}>
                            - Explanations concerning the collapse of Tikal remain controversial.  Some of these possible explanations include large scale conflict and war.
                        </Typography>

                        <Typography align="justify">Readings: </Typography>

                        <Typography align="justify" style={{margin: 10}}>
                            - Canuto MA., et al. Ancient lowland Maya complexity as revealed by airborne laser scanning of northern Guatemala.  Science 361, 1355 (2018)
                        </Typography>

                        <Typography align="justify" style={{margin: 10}}>
                            - Wahl, D., Anderson, L., Estrada-Belli, F. et al. Palaeoenvironmental, epigraphic and archaeological evidence of total warfare among the Classic Maya. Nat Hum Behav 3, 1049–1054 (2019). <Link href="https://doi.org/10.1038/s41562-019-0671-x" target="_blank" color="error">Link</Link> 
                        </Typography>
                    </AccordionDetails>

                </Accordion>
        </React.Fragment>
    )
}