import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TheBox from "../components/TheBox";
import conceptualFlechas from "../resources/conceptualFrameworkFlechas.png"

export default function IntroActivity2(props){
    return(
        <React.Fragment>
            <Typography variant="h6">Activity 2</Typography>
            <Typography align="justify">
                Students write a short essay (150 words) describing the links between the urban  built environment and public health policy concerning their own city.  Use a conceptual framework (see example, below).  
            </Typography>
            <Typography align="justify">
                The following chapters will introduce students into step-wise case based discussions, showing how data illuminate ethical, political, economic, environmental and public health issues.
            </Typography>

            <Divider style={{marginTop:20, marginBottom:20 ,marginLeft:100, marginRight:100}}/>
            
            <Typography variant="h6">CONCEPTUAL FRAMEWORK</Typography>

            <TheBox />
            <img alt="map" width={anchoSegunBreakpoint(props.size)} height="auto" src={conceptualFlechas}  />
        </React.Fragment>
    )
}

function anchoSegunBreakpoint(size){
    let margin = 25
    if (size["bpoint"] === "xl" || size["bpoint"] === "lg") {
        return 900 - (margin * 2)
    }
    return size["width"] - (margin * 2)
}