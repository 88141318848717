import React from "react";
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import TheBox from "./TheBox";

export default function Loading(){
    return(
        <React.Fragment>
            <Container maxWidth="lg">
                <TheBox />
                <Stack direction="row" justifyContent={"center"}>
                    <CircularProgress />
                </Stack>
            </Container>
        </React.Fragment>
    )
}