import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ExpandMore from "@mui/icons-material/ExpandMore";

export default function IntroBox2(){
    const elevationBaja = 3
    const elevationAlta = 10
    const [elevationAcc, setElevationAcc] = React.useState(elevationBaja)

    function toggleElevation(expanded){
        console.log(expanded)
        if (expanded){
            setElevationAcc(elevationAlta)
        }
        if (expanded === false){
            setElevationAcc(elevationBaja)
        }
    }

    return(
        <React.Fragment>
            <Accordion elevation={elevationAcc} onChange={ (event, expanded) => { toggleElevation(expanded) }}> 
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography fontWeight={600}>Box 2: Planetary crisis: climate, syndemics,  war. </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            The public health impact of economic activities may extend beyond cities’ administrative boundaries. 
                            Current global forces shape policies that frame markets as source of progress and growth, and government regulations as barriers. 
                            In practice these forces have supported powerful business over local demands (e.g., housing, water and sanitation, health systems, education). 
                            Global change has been dominated by deregulation policy affecting many sectors of the society, and disinvestment in basic human needs  (Chomsky 2011).
                        </Typography>

                        <Typography style={{marginBottom: 10}}>
                            Public health, environmental justice and human rights battles will be waged in rapidly growing cities. 
                            Economic driving forces, weak institutions and demographic pressure are resulting in explosive crowding and social inequalities, which makes public health, environmental risk and urban policy increasingly challenging.  
                            Climate change, pandemics and war are making everything even worse. 
                        </Typography>

                        <Typography>Readings: </Typography>

                        <Typography style={{margin: 10}}>
                            - The World Population Just Hit 8 Billion and Here's How It Will Continue to Grow – <Link href="https://www.scientificamerican.com/article/the-world-population-just-hit-8-billion-and-heres-how-it-will-continue-to-grow/" target="_blank" color="error">Scientific American</Link> 
                        </Typography>

                        <Typography style={{margin: 10}}>
                            - The future is urban: integrated planning policies can enable healthy and sustainable cities - <Link href="https://www.thelancet.com/journals/langlo/article/PIIS2214-109X(22)00211-X/fulltext" target="_blank" color="error">The Lancet Global Health</Link> 
                        </Typography>

                        <Typography style={{margin: 10}}>
                            - The 2022 China report of the Lancet Countdown on health and climate change: leveraging climate actions for healthy ageing - <Link href="https://www.thelancet.com/journals/lanpub/article/PIIS2468-2667(22)00224-9/fulltext?dgcid=raven_jbs_aip_email" target="_blank" color="error">The Lancet Public Health</Link>  
                        </Typography>

                        <Typography style={{margin: 10}}>
                            - Creating healthy and sustainable cities: what gets measured, gets done - <Link href="https://www.thelancet.com/journals/langlo/article/PIIS2214-109X(22)00070-5/fulltext" target="_blank" color="error">The Lancet Global Health</Link>  
                        </Typography>

                        <Typography style={{margin: 10}}>
                            - Diseases explode after extreme flooding and other climate disasters - <Link href="https://www.scientificamerican.com/article/diseases-explode-after-extreme-flooding-and-other-climate-disasters/" target="_blank" color="error">Scientific American</Link>  
                        </Typography>

                        <Typography style={{margin: 10}}>
                            - Where the Rapid Rise of Megacities Is Unsustainable - <Link href="https://www.bloomberg.com/news/articles/2022-10-19/the-unsustainable-rise-of-megacities-in-africa-and-asia?utm_campaign=news&utm_medium=bd&utm_source=applenews" target="_blank" color="error">Bloomberg</Link>  
                        </Typography>

                        <Typography style={{margin: 10}}>
                            - The worst drought in 1,200 years - <Link href="https://www.bbc.com/news/world-us-canada-32150064" target="_blank" color="error">BBC Future </Link> 
                        </Typography>

                        <Typography style={{margin: 10}}>
                            - Chomsky, Noam. 2011. Profit Over People: Neoliberalism and Global Order. New York: Seven Stories Press.  <Link href="https://scholar.google.com/scholar_lookup?title=Profit+Over+People%3A+Neoliberalism+and+Global+Order&author=Chomsky+Noam&publication+year=2011" target="_blank" color="error">Google Scholar</Link>
                        </Typography>

                        <Typography style={{margin: 10}}>
                            - A Small Cut in World Military Spending Could Help Fund Climate Change, Health and Poverty Solutions - <Link href="https://www.scientificamerican.com/article/a-small-cut-in-world-military-spending-could-help-fund-climate-health-and-poverty-solutions/" target="_blank" color="error">Scientific American</Link>  
                        </Typography>

                    </AccordionDetails>

                </Accordion>

        </React.Fragment>
    )
}