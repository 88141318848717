import React from "react";
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Button, Stack, Dialog, Divider, Card, CardMedia } from "@mui/material";
import { Container } from "@mui/system";

export default function ImagenCentrada(props){
    return(
        <React.Fragment>
            <Container maxWidth={props.width}>
                
                <Card elevation={0}>
                    <CardMedia 
                    component="img"
                    image={props.image}
                    alt={props.text}
                />
                </Card>
                <Stack direction="row" justifyContent={"center"}>
                    <Typography variant="caption" align="center">{props.text}</Typography>
                </Stack>
            </Container>
        </React.Fragment>
    )
}