import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { useNavigate, Link } from "react-router-dom"

export default function CardCaratulaCapitulo2(props){
    const navigate = useNavigate()
    const [brightness, setBrightness] = useState(0.4);

    return(
        <React.Fragment>
            <Card square={true} style={{cursor: 'pointer'}} component={Link} to={`${props.link}`} /* onClick={() => {navigate(`${props.link}`)}} */ onMouseEnter={() => setBrightness(0.2)} onMouseLeave={() => setBrightness(0.4) } sx={{width: 1}}>
                <div style={{ position: "relative" }}>
                    
                    <CardMedia 
                        component="img"
                        image={props.foto}
                        style={{ aspectRatio: 16 / 3, filter: `brightness(${brightness})` }}
                        alt="preview of chapter image"
                    />

                    <div style={{ position:"absolute", top:"50%", left:"50%", transform:"translateX(-50%) translateY(-50%)" }}>
                        <Typography variant="h6" style={{color: "#ddd"}}>{props.antetitulo}</Typography>
                        <Typography variant="h4" style={{color: "#ddd"}} sx={{ fontWeight: 600 }}>{props.texto}</Typography>
                    </div>

                </div>
            </Card>
        </React.Fragment>
    )
}
