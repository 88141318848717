import React from "react";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TheBox from "../components/TheBox";

export default function PaginaNoMatch(){
    /* window.scrollTo(0, 0) */

    return (
        <React.Fragment>
            <Container maxWidth="lg">
                <TheBox />
                <Typography>Pagina NoMatch</Typography>
            </Container>

        </React.Fragment>
    )
}
