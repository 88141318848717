import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Skeleton from '@mui/material/Skeleton';
import Container from '@mui/material/Container';

import EnriqueFoto from "../resources/enriqueCWeb.jpg"
import Email from "@mui/icons-material/Email"

export default function CardEnrique(props){
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [loading, setLoading] = React.useState(true)
    let altoEsqueleto = altoSkeleton(props.size)

    return(
        <React.Fragment>
            <img src={EnriqueFoto} style={{position:"absolute", opacity:0, maxWidth: 1}} alt="for preloading another one" onLoad={() => {setLoading(false)}} />
            <Card style={{flexGrow: 1}}>
            { loading ? 
                    <Container maxWidth={false}>
                        <Skeleton variant="rectangular" height={altoEsqueleto} /> 
                    </Container>
                :
                <CardMedia 
                    component="img"
                    image={EnriqueFoto}
                />
            }
                <CardContent>
                    <Stack direction="row" alignItems="center">
                        <Typography variant={"h6"}>ENRIQUE CIFUENTES</Typography>
                        <IconButton onClick={() => {navigator.clipboard.writeText("ecifuent@gmail.com"); setOpenSnackbar(true) }}>
                            <Email/>
                        </IconButton>
                    </Stack>
                    <Typography>Visiting Professor and Lecturer, at the Mount Sinai School of Medicine, New York. </Typography>
                    <Typography>Former Research Scientist, at the Harvard School of Public Health. </Typography>
                    <Typography>PhD and MMed Sci., London School of Tropical Medicine and Hygiene. University of London. </Typography>
                    <Typography>MD, UNAM (Mexico).</Typography>
                </CardContent>
            </Card>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                /* onClose={setOpenSnackbar(false)} */
                message="Email adress copied to clipboard"
            />
        </React.Fragment>
    )
}

function altoSkeleton(size){
    if (size["bpoint"] === "xs" || size["bpoint"] === "sm"){
        return size["width"] 
    }

    if (size["bpoint"] === "xl"){
        return ( 1200 - 50 ) / 3
    }

    return size["width"] / 3 -50
}