import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TheBox from "../components/TheBox";
import CardCaratulaCapitulo3 from "../components/CardCaratulaCapitulo3";

import slumsCaracas from "../resources/slumsCaracasWeb2.jpg"
import heatWaves from "../resources/heatWaves.jpg"
import airQuality from "../resources/airQuality.jpg"
import substanceAbuse from "../resources/substanceAbuse2.jpg"
import warOnDrugs3 from "../resources/warOnDrugs3.webp"
import climateChange from "../resources/climateChange.jpg"
import neighbourhoodEffect from "../resources/neighbourhoodEffect.jpg"
import waterSanitation from "../resources/waterSanitation.webp"
import familyFood from "../resources/familyFood.png"
import intro from "../resources/negociosVilleros.jpg"

export default function PaginaIndex(){
    let size = WindowSize()
    /* window.scrollTo(0, 0) */

    return (
        <React.Fragment>
            <TheBox />
            <TheBox />
            <Container maxWidth="lg">
            <Grid container spacing={4}>

                <Grid item xs={12} md={6} lg={4} style={{display: 'flex', }}>
                    <CardCaratulaCapitulo3 link={"Chapter1"} antetitulo={"Chapter 1"} texto={"Introduction"} foto={intro} size={size} />
                </Grid>

                <Grid item xs={12} md={6} lg={4} style={{display: 'flex', }}>
                    <CardCaratulaCapitulo3 link={"Chapter2"} antetitulo={"Chapter 2"} texto={"Public health in mega-slums"} foto={slumsCaracas} size={size}/>
                </Grid>

                <Grid item xs={12} md={6} lg={4} style={{display: 'flex', }}>
                    <CardCaratulaCapitulo3 link={"Chapter3"} antetitulo={"Chapter 3"} texto={"Water, sanitation and public health in rapidly growing cities"} foto={waterSanitation} size={size}/>
                </Grid>

                <Grid item xs={12} md={6} lg={4} style={{display: 'flex', }}>
                    <CardCaratulaCapitulo3 link={"Chapter4"} antetitulo={"Chapter 4"} texto={"Food systems and obesity in rapidly growing cities: a syndemic approach"} foto={familyFood} size={size}/>
                </Grid>

                <Grid item xs={12} md={6} lg={4} style={{display: 'flex', }}>
                    <CardCaratulaCapitulo3 link={"Chapter5"} antetitulo={"Chapter 5"} texto={"Beyond the built enviroment; the neighbourhood effect. A 'case' study"} foto={neighbourhoodEffect} size={size}/>
                </Grid>

                <Grid item xs={12} md={6} lg={4} style={{display: 'flex', }}>
                    <CardCaratulaCapitulo3 link={"Chapter6"} antetitulo={"Chapter 6"} texto={"Climate crisis and public health"} foto={climateChange} size={size}/>
                </Grid>

                <Grid item xs={12} md={6} lg={4} style={{display: 'flex', }}>
                    <CardCaratulaCapitulo3 link={"Chapter7"} antetitulo={"Chapter 7"} texto={"Heat waves in rapidly growing cities: Public health emergencies"} foto={heatWaves} size={size}/>
                </Grid>

                <Grid item xs={12} md={6} lg={4} style={{display: 'flex', }}>
                    <CardCaratulaCapitulo3 link={"Chapter8"} antetitulo={"Chapter 8"} texto={"Air quality and public health in rapidly growing cities"} foto={airQuality} size={size}/>
                </Grid>

                <Grid item xs={12} md={6} lg={4} style={{display: 'flex', }}>
                    <CardCaratulaCapitulo3 link={"Chapter9"} antetitulo={"Chapter 9"} texto={"Mental health in megacities. Substance abuse"} foto={substanceAbuse} size={size}/>
                </Grid>

                <Grid item xs={12} md={6} lg={4} style={{display: 'flex', }}>
                    <CardCaratulaCapitulo3 link={"Chapter10"} antetitulo={"Chapter 10"} texto={"Urban violence: The war on drugs"} foto={warOnDrugs3} size={size}/>
                </Grid>

            </Grid>
            </Container>
            <TheBox />
            <TheBox />
        </React.Fragment>
    )
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
  }

function WindowSize() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    if (windowDimensions["width"] < 600){
      windowDimensions["bpoint"] = "xs"
    }
    else if (windowDimensions["width"] < 900){
      windowDimensions["bpoint"] = "sm"
    }
    else if (windowDimensions["width"] < 1200){
      windowDimensions["bpoint"] = "md"
    }
    else if (windowDimensions["width"] < 1536){
      windowDimensions["bpoint"] = "lg"
    }
    else {
      windowDimensions["bpoint"] = "xl"
    }
    console.log(windowDimensions)
    return windowDimensions;
}