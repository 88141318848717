import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
/* import { BreakpointIndicator } from 'mui-breakpoint-indicator'; */
import CssBaseline from "@mui/material/CssBaseline";
import tema from "./resources/tema.js"
import { useState, useEffect } from 'react';

import PaginaPrincipal from './pages/PaginaPrincipal.js';
import PaginaIntegrantes from './pages/PaginaIntegrantes.js';
import PaginaIndex from './pages/PaginaIndex.js';
import PaginaNoMatch from './pages/PaginaNoMatch.js';
import UpBar from './components/UpBar.js';
import PaginaIntroduccion from './pages/capitulos/PaginaIntroduccion.js';
import Loading from './components/Loading.js';
import ScrollToTop from './components/ScrollToTop.js';

const PaginaCapitulo1 = lazy(() => import('./pages/capitulos/PaginaIntroduccion.js'));
const PaginaCapitulo2 = lazy(() => import('./pages/capitulos/PaginaSlumsAndMegacities.js'));
const PaginaCapitulo3 = lazy(() => import('./pages/capitulos/PaginaWaterSanitation.js'));
const PaginaCapitulo3BlockA = lazy(() => import('./pages/capitulos/PaginaWaterSBlockA.js'));
const PaginaCapitulo3BlockB = lazy(() => import('./pages/capitulos/PaginaWaterSBlockB.js'));
const PaginaCapitulo3BlockC = lazy(() => import('./pages/capitulos/PaginaWaterSBlockC.js'));
const PaginaCapitulo4 = lazy(() => import('./pages/capitulos/PaginaFoodSystems.js'));
const PaginaCapitulo5 = lazy(() => import('./pages/capitulos/PaginaNeighbourdhoodEffect.js'));
const PaginaCapitulo6 = lazy(() => import('./pages/capitulos/PaginaCCAndHealth.js'));
const PaginaCapitulo7 = lazy(() => import('./pages/capitulos/PaginaHeatWaves.js'));
const PaginaCapitulo8 = lazy(() => import('./pages/capitulos/PaginaAirQuality.js'));
const PaginaCapitulo9 = lazy(() => import('./pages/capitulos/PaginaMentalHealth.js'));
const PaginaCapitulo10 = lazy(() => import('./pages/capitulos/PaginaUrbanViolence.js'));

export default function App(){
    let size = WindowSize()

	return(
		<React.Fragment>
			<ThemeProvider theme={tema}>
			<CssBaseline />
				<UpBar size={size}/>
        <ScrollToTop />
                <Suspense fallback={ <Loading /> }>
                    <Routes>
                        <Route index element={<PaginaPrincipal size={size}/>} />
                        <Route path="members" element={<PaginaIntegrantes size={size}/>} />
                        <Route path="index" element={<PaginaIndex />} />
                        <Route path="Introduction" element={<PaginaIntroduccion size={size}/>} />
                        <Route path="index/Chapter1" element={<PaginaCapitulo1 size={size}/>} />
                        <Route path="index/Chapter2" element={<PaginaCapitulo2 size={size}/>} />
                        <Route path="index/Chapter3" element={<PaginaCapitulo3 size={size}/>} />
                        <Route path="index/Chapter3/BlockA" element={<PaginaCapitulo3BlockA size={size}/>} />
                        <Route path="index/Chapter3/BlockB" element={<PaginaCapitulo3BlockB size={size}/>} />
                        <Route path="index/Chapter3/BlockC" element={<PaginaCapitulo3BlockC size={size}/>} />
                        <Route path="index/Chapter4" element={<PaginaCapitulo4 size={size}/>} />
                        <Route path="index/Chapter5" element={<PaginaCapitulo5 size={size}/>} />
                        <Route path="index/Chapter6" element={<PaginaCapitulo6 size={size}/>} />
                        <Route path="index/Chapter7" element={<PaginaCapitulo7 size={size}/>} />
                        <Route path="index/Chapter8" element={<PaginaCapitulo8 size={size}/>} />
                        <Route path="index/Chapter9" element={<PaginaCapitulo9 size={size}/>} />
                        <Route path="index/Chapter10" element={<PaginaCapitulo10 size={size}/>} />
                        <Route path="*" element={<PaginaNoMatch />} />
                    </Routes>
                </Suspense>
			</ThemeProvider>
		</React.Fragment>
	)
} 

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
  }

function WindowSize() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    if (windowDimensions["width"] < 600){
      windowDimensions["bpoint"] = "xs"
    }
    else if (windowDimensions["width"] < 900){
      windowDimensions["bpoint"] = "sm"
    }
    else if (windowDimensions["width"] < 1200){
      windowDimensions["bpoint"] = "md"
    }
    else if (windowDimensions["width"] < 1536){
      windowDimensions["bpoint"] = "lg"
    }
    else {
      windowDimensions["bpoint"] = "xl"
    }
    console.log(windowDimensions)
    return windowDimensions;
}