import React from "react";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import LinkMUI from '@mui/material/Link';
import Grow from '@mui/material/Grow';

import ParTextoImagenEntrada from "../components/ParTextoImagenEntrada";
import PrefacioDePagina from "../components/PrefacioDePagina";
import TheBox from "../components/TheBox";
import CardCaratulaCapitulo2 from "../components/CardCaratulaCapitulo2";
import { useInView } from "react-intersection-observer";

import slumsCaracasWeb from "../resources/slumsCaracasWeb2.jpg"
import slumsCaracasCell from "../resources/slumsCaracasCell2.jpg"
import aguaSuciaWeb from "../resources/aguaSuciaWeb.jpg"
import aguaSuciaCell from "../resources/aguaSuciaCell.jpg"

export default function PaginaPrincipal(props){
    let slumsCaracas = slumsCaracasWeb
    let aguaSucia = aguaSuciaWeb
    if (props.size["bpoint"] === "xs" || props.size["bpoint"] === "sm" || props.size["bpoint"] === "md") {
        slumsCaracas = slumsCaracasCell
        aguaSucia = aguaSuciaCell
    }

    const { ref, inView } = useInView({
        triggerOnce: true,
        delay: 100,
        threshold: 0.4
    });

    return (
        <React.Fragment>
            <TheBox />
            { props.size.bpoint === "xs" || props.size.bpoint === "sm" ? null : <TheBox /> } 
            <ParTextoImagenEntrada size={props.size} />

            <Container maxWidth="lg">
                <TheBox />
                <TheBox />
                <Divider style={{margin: 0}} />
                <TheBox />
            </Container>

            <PrefacioDePagina/>

            <Container maxWidth="lg">
                <TheBox />
                <Divider style={{margin: 0}} />
                <TheBox />
            </Container>

            <Container maxWidth="lg">
                <Grid container spacing={4} justifyContent="center" ref={ref}>

                <Grow in={inView} timeout={{enter: 1000}}>
                    <Grid item xs={12} style={{display: 'flex', }}>
                        <CardCaratulaCapitulo2 antetitulo={"Index"} link={"Index"} texto={"Chapters"} foto={aguaSucia}/>
                    </Grid>
                </Grow>

                </Grid>
            </Container>
            <TheBox />

            <Container maxWidth="lg">
                <Typography>Acknowledgement: This electronic book  has received no external funds.</Typography>
                <Typography>© Site and contents by  <LinkMUI href="members" color="error">E. Cifuentes, B. Rubiell and S. Langer</LinkMUI>.</Typography>
            </Container>
            <TheBox/>
        </React.Fragment>
    )
}