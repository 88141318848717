import React from "react";
import Introduccion from "../../components/Introduccion";
import TheBox from "../../components/TheBox";

export default function PaginaIntroduccion(props){
    /* window.scrollTo(0, 0) */
    return (
        <React.Fragment>
            <TheBox />
            <Introduccion size={props.size}/>
            <TheBox />
        </React.Fragment>
    )
}