import React, { useState, } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Container from '@mui/material/Container';

import { useNavigate, Link } from "react-router-dom"

export default function CardCaratulaCapitulo3(props){
    const navigate = useNavigate()
    const [brightness, setBrightness] = useState(1); // No es brightness, es elevation
    const [loading, setLoading] = React.useState(true)
    let altoEsqueleto = altoSkeleton(props.size)

    return(
        <React.Fragment>
            <img src={props.foto} style={{position:"absolute", opacity:0, maxWidth: 1}} alt="for preloading another one" onLoad={() => {setLoading(false)}} />
            <Card elevation={brightness} square={true} style={{cursor: 'pointer', textDecoration: 'none'}} component={Link} to={`${props.link}`} onMouseEnter={() => setBrightness(10)} onMouseLeave={() => setBrightness(1)}>
            { loading ? 
                    <Container maxWidth={false}>
                        <Skeleton variant="rectangular" height={altoEsqueleto} width={altoEsqueleto / 9 * 16} /> 
                    </Container>
                :
                <CardMedia 
                    component="img"
                    image={props.foto}
                    style={{ aspectRatio: 16 / 9, filter: `brightness(${0.9})` }}
                />
            }
                
                <CardContent>
                    <Typography variant="h6" style={{flexGrow: 1, textAlign: "center"}}>{props.antetitulo}</Typography>
                    <Typography variant="h4" sx={{ fontWeight: 600 }} style={{flexGrow: 1, textAlign: "center"}}>{props.texto}</Typography>
                </CardContent>

            </Card>
        </React.Fragment>
    )
}

function altoSkeleton(size){
    if (size["bpoint"] === "xs" || size["bpoint"] === "sm"){
        return (size["width"] - 5 ) / 1 * 9 / 16
    }
    if (size["bpoint"] === "md"){
        return (size["width"] - 5 ) / 2 * 9 / 16
    }
    if (size["bpoint"] === "lg"){
        return (size["width"] - 15 ) / 3 * 9 / 16
    }
    if (size["bpoint"] === "xl"){
        return (1200 - 15 ) / 3 * 9 / 16
    }
    return 300
}