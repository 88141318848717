import React from 'react';
import ReactDOM from 'react-dom/client';
import './resources/index.css';
import App from './App';
/* import reportWebVitals from './reportWebVitals'; */
import { BrowserRouter, } from 'react-router-dom';



// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCWw-2lb66-DgD6hMy5igbJV_1fzbE4vYU",
  authDomain: "cifuentesweb.firebaseapp.com",
  projectId: "cifuentesweb",
  storageBucket: "cifuentesweb.appspot.com",
  messagingSenderId: "877331279796",
  appId: "1:877331279796:web:2dcecec36c580e51370ce1",
  measurementId: "G-2LRLWJJKLR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// https://bit.ly/CRA-vitals
/* reportWebVitals(); */
