import React from "react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";
import LinkMUI from '@mui/material/Link';

import video from "../resources/videoShibu.mp4"

export default function Par(props){
    const [loading, setLoading] = React.useState(true)
    let margenArriba = 0
    if (props.size["bpoint"] === "xs" || props.size["bpoint"] === "sm" || props.size["bpoint"] === "md") {
        margenArriba = 20
    }

    return(
        <React.Fragment>
            <video muted style={{position:"absolute", opacity:0, maxWidth: 1}} alt="for preloading another one" onCanPlay={() => {setLoading(false)}}>
                <source src={video} />
            </video>
            
            <Container maxWidth="lg">

            <Grid container alignItems="center" spacing={5}>

                <Grid item xs={12} md={4} style={{display: 'flex' }}>
                    <Card square elevation={0} >
                        <Typography variant="h3" style={{marginTop: margenArriba }} sx={{ fontWeight: 600 }}>Public health in rapidly growing cities; the environmental dimension.</Typography>
                        <Typography variant="h6" style={{marginTop:  10, marginLeft: 2 }} sx={{ fontWeight: 500 }}><LinkMUI style={{cursor: 'pointer', color: 'inherit', textDecoration: 'none'}} href="members" color="error">By Enrique Cifuentes, Bárbara Rubiell and Santiago Langer.</LinkMUI></Typography>
                    </Card>
                </Grid>
                
                <Grid item xs={12} md={8} style={{display: 'flex'}}>
                { loading ? 
                    <Container maxWidth={false}>
                        <Skeleton variant="rectangular" height={500} /> {/* No dejar este 500px arbitrario */}
                    </Container>
                :
                    <Card square elevation={0} sx={{ width: 1, }}>
                        <CardMedia 
                            component="video"
                            image={video}
                            autoPlay
                            muted
                            loop
                            style={{ aspectRatio: 16 / 9, outline: "none", overflow:"hidden"}}
                            alt="Cicling images of many topics"
                        />

                    </Card>
                }
                </Grid>
            </Grid>

            </Container>
        </React.Fragment>
    )
    
}

/* 
<Typography variant="h3" style={{marginTop: margenArriba }} sx={{ fontWeight: 600 }}>Public health in rapidly growing cities; </Typography>
                        <Typography variant="h3" style={{marginTop: margenArriba }} sx={{ fontWeight: 400 }}>the environmental dimension.</Typography> */