import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let tema = createTheme({
    palette:{
        primary:{
            main: "#ddd"
        },
        secondary:{
            main: "#eee"
        },
        errorLight:{
            main: "#DF3131",
            contrastText: "#fff"
        }
    },
    typography: {
        fontFamily: 'Lora',
        h1: {
            fontFamily: 'Playfair Display',
        },
        h2: {
            fontFamily: 'Playfair Display',
        },
        h3: {
            fontFamily: 'Playfair Display',
        },
        h4: {
            fontFamily: 'Playfair Display',
        },
        h5: {
            fontFamily: 'Playfair Display',
            fontWeight: 500,
        },
        h6: {
            fontFamily: 'Playfair Display',
        },
    },
})

let options = {
    factor: 2
}

tema = responsiveFontSizes(tema, options);

export default tema