import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Skeleton from '@mui/material/Skeleton';
import Container from '@mui/material/Container';
import Email from "@mui/icons-material/Email"

import barbaraFoto from "../resources/barbaraCWeb.jpg"

export default function CardBarbara(props){
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [loading, setLoading] = React.useState(true)
    let altoEsqueleto = altoSkeleton(props.size)

    return(
        <React.Fragment>
            <img src={barbaraFoto} style={{position:"absolute", opacity:0, maxWidth: 1}} alt="for preloading another one" onLoad={() => {setLoading(false)}} />
            <Card style={{flexGrow: 1}} >
            { loading ? 
                    <Container maxWidth={false}>
                        <Skeleton variant="rectangular" height={altoEsqueleto} width={altoEsqueleto} /> 
                    </Container>
                :
                <CardMedia 
                    component="img"
                    image={barbaraFoto}
                />
            }
                <CardContent>
                    <Stack direction="row" alignItems="center">
                        <Typography variant={"h6"}>BÁRBARA RUBIELL</Typography>
                        <IconButton onClick={() => {navigator.clipboard.writeText("barubiell@gmail.com"); setOpenSnackbar(true) }}>
                            <Email/>
                        </IconButton>
                    </Stack>
                    <Typography>PhD in Geography at the University of Edinburgh.  Her PhD project aimed to understand the relationship between trade agreements and obesity by assessing the changes in food environments in the last three decades. </Typography>
                    <Typography>She worked as teaching assistant for the course "Public Health in Megacities" </Typography>
                    <Typography>Currently she works as a social researcher for the National Office of Statistics (United Kingdom), at the Health Analysis and pandemic Insights division. </Typography>
                </CardContent>
            </Card>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                /* onClose={setOpenSnackbar(false)} */
                message="Email adress copied to clipboard"
            />
        </React.Fragment>
    )
}

function altoSkeleton(size){
    if (size["bpoint"] === "xs" || size["bpoint"] === "sm"){
        return size["width"] 
    }

    if (size["bpoint"] === "xl"){
        return ( 1200 - 50 ) / 3
    }

    return size["width"] / 3 -50
}